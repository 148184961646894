@font-face {
    font-family: 'webicons';
    src: url('../assets/icon/webicons/v1/bgisicons.eot');
    src: url('../assets/icon/webicons/v1/bgisicons.eot') format('embedded-opentype'),
         url('../assets/icon/webicons/v1/bgisicons.woff') format('woff'),
         url('../assets/icon/webicons/v1/bgisicons.ttf') format('truetype'),
         url('../assets/icon/webicons/v1/bgisicons.svg') format('svg');
}

.bgis-icon{
    font-family: "webicons";
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;
    line-height: 1rem;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-signal:before { content: '\e800'; } /* '' */
.icon-list-add:before { content: '\e801'; } /* '' */
.icon-ccw:before { content: '\e802'; } /* '' */
.icon-cw:before { content: '\e803'; } /* '' */
.icon-right-open:before { content: '\e804'; } /* '' */
.icon-up-open:before { content: '\e805'; } /* '' */
.icon-retweet:before { content: '\e806'; } /* '' */
.icon-forward:before { content: '\e807'; } /* '' */
.icon-reply:before { content: '\e808'; } /* '' */
.icon-credit-card:before { content: '\e809'; } /* '' */
.icon-floppy:before { content: '\e80a'; } /* '' */
.icon-clipboard:before { content: '\e80b'; } /* '' */
.icon-mail:before { content: '\e80c'; } /* '' */
.icon-vcard:before { content: '\e80d'; } /* '' */
.icon-login:before { content: '\e80e'; } /* '' */
.icon-arrows-ccw:before { content: '\e80f'; } /* '' */
.icon-doc-text:before { content: '\e810'; } /* '' */
.icon-basket:before { content: '\e811'; } /* '' */
.icon-folder:before { content: '\e812'; } /* '' */
.icon-help:before { content: '\e813'; } /* '' */
.icon-help-circled:before { content: '\e814'; } /* '' */
.icon-info:before { content: '\e815'; } /* '' */
.icon-info-circled:before { content: '\e816'; } /* '' */
.icon-home:before { content: '\e817'; } /* '' */
.icon-lock:before { content: '\e818'; } /* '' */
.icon-lock-open:before { content: '\e819'; } /* '' */
.icon-camera:before { content: '\e81a'; } /* '' */
.icon-users:before { content: '\e81b'; } /* '' */
.icon-user-add:before { content: '\e81c'; } /* '' */
.icon-user:before { content: '\e81d'; } /* '' */
.icon-search:before { content: '\e81e'; } /* '' */
.icon-export:before { content: '\e81f'; } /* '' */
.icon-phone:before { content: '\e820'; } /* '' */
.icon-paper-plane:before { content: '\e821'; } /* '' */
.icon-mobile:before { content: '\e822'; } /* '' */
.icon-monitor:before { content: '\e823'; } /* '' */
.icon-chart-bar:before { content: '\e824'; } /* '' */
.icon-trash:before { content: '\e825'; } /* '' */
.icon-eye:before { content: '\e826'; } /* '' */
.icon-newspaper:before { content: '\e827'; } /* '' */
.icon-book-open:before { content: '\e828'; } /* '' */
.icon-cd:before { content: '\e829'; } /* '' */
.icon-cancel-circled:before { content: '\e82a'; } /* '' */
.icon-cancel-squared:before { content: '\e82b'; } /* '' */
.icon-link:before { content: '\e82c'; } /* '' */
.icon-cup:before { content: '\e82d'; } /* '' */
.icon-direction:before { content: '\e82e'; } /* '' */
.icon-alert:before { content: '\e82f'; } /* '' */
.icon-window:before { content: '\e830'; } /* '' */
.icon-block:before { content: '\e831'; } /* '' */
.icon-down-bold:before { content: '\e832'; } /* '' */
.icon-left-bold:before { content: '\e833'; } /* '' */
.icon-right-bold:before { content: '\e834'; } /* '' */
.icon-up-bold:before { content: '\e835'; } /* '' */
.icon-down-thin:before { content: '\e836'; } /* '' */
.icon-left-thin:before { content: '\e837'; } /* '' */
.icon-right-thin:before { content: '\e838'; } /* '' */
.icon-up-thin:before { content: '\e839'; } /* '' */
.icon-down:before { content: '\e83a'; } /* '' */
.icon-left:before { content: '\e83b'; } /* '' */
.icon-right:before { content: '\e83c'; } /* '' */
.icon-up:before { content: '\e83d'; } /* '' */
.icon-down-1:before { content: '\e83e'; } /* '' */
.icon-left-1:before { content: '\e83f'; } /* '' */
.icon-right-1:before { content: '\e840'; } /* '' */
.icon-up-1:before { content: '\e841'; } /* '' */
.icon-profile:before { content: '\e842'; } /* '' */
.icon-search-1:before { content: '\e843'; } /* '' */
.icon-search-3:before { content: '\e844'; } /* '' */
.icon-music:before { content: '\e845'; } /* '' */
.icon-plus-circle:before { content: '\e846'; } /* '' */
.icon-plus-1:before { content: '\e847'; } /* '' */
.icon-location-green:before { content: '\e848'; } /* '' */
.icon-location-grey:before { content: '\e849'; } /* '' */
.icon-minus-1:before { content: '\e84a'; } /* '' */
.icon-pencil-1:before { content: '\e84b'; } /* '' */
.icon-phone-1:before { content: '\e84c'; } /* '' */
.icon-mail-1:before { content: '\e84d'; } /* '' */
.icon-heart:before { content: '\e84e'; } /* '' */
.icon-trash-1:before { content: '\e84f'; } /* '' */
.icon-triangle:before { content: '\e850'; } /* '' */
.icon-user-avatar:before { content: '\e851'; } /* '' */
.icon-star-3:before { content: '\e852'; } /* '' */
.icon-star-empty-1:before { content: '\e853'; } /* '' */
.icon-filter:before { content: '\e854'; } /* '' */
.icon-cancel:before { content: '\e874'; } /* '' */
.icon-popup:before { content: '\e875'; } /* '' */
.icon-calendar:before { content: '\e876'; } /* '' */
.icon-chat:before { content: '\e877'; } /* '' */
.icon-attach:before { content: '\e878'; } /* '' */
.icon-pencil:before { content: '\e879'; } /* '' */
.icon-attention:before { content: '\e87a'; } /* '' */
.icon-down-open-big:before { content: '\e87b'; } /* '' */
.icon-left-open-big:before { content: '\e87c'; } /* '' */
.icon-right-open-big:before { content: '\e87d'; } /* '' */
.icon-up-open-big:before { content: '\e87e'; } /* '' */
.icon-plus:before { content: '\e87f'; } /* '' */
.icon-plus-circled:before { content: '\e880'; } /* '' */
.icon-plus-squared:before { content: '\e881'; } /* '' */
.icon-minus:before { content: '\e882'; } /* '' */
.icon-minus-circled:before { content: '\e883'; } /* '' */
.icon-minus-squared:before { content: '\e884'; } /* '' */
.icon-download:before { content: '\e885'; } /* '' */
.icon-upload:before { content: '\e886'; } /* '' */
.icon-location:before { content: '\e887'; } /* '' */
.icon-flash:before { content: '\e888'; } /* '' */
.icon-down-open:before { content: '\e889'; } /* '' */
.icon-left-open:before { content: '\e88a'; } /* '' */
.icon-list:before { content: '\e88b'; } /* '' */
.icon-suitcase:before { content: '\e88c'; } /* '' */
.icon-database:before { content: '\e88d'; } /* '' */
.icon-lamp:before { content: '\e88e'; } /* '' */
.icon-print:before { content: '\e88f'; } /* '' */
.icon-comment:before { content: '\e890'; } /* '' */
.icon-picture:before { content: '\e891'; } /* '' */
.icon-star:before { content: '\e892'; } /* '' */
.icon-star-empty:before { content: '\e893'; } /* '' */
.icon-doc-text-inv:before { content: '\e894'; } /* '' */
.icon-book:before { content: '\e895'; } /* '' */
.icon-quote:before { content: '\e896'; } /* '' */
.icon-arrow-combo:before { content: '\e897'; } /* '' */
.icon-down-open-mini:before { content: '\e898'; } /* '' */
.icon-right-dir:before { content: '\e899'; } /* '' */
.icon-left-dir:before { content: '\e89a'; } /* '' */
.icon-down-dir:before { content: '\e89b'; } /* '' */
.icon-up-open-mini:before { content: '\e89c'; } /* '' */
.icon-right-open-mini:before { content: '\e89d'; } /* '' */
.icon-left-open-mini:before { content: '\e89e'; } /* '' */
.icon-dot-3:before { content: '\e89f'; } /* '' */
.icon-flow-tree:before { content: '\e8a0'; } /* '' */
.icon-flow-line:before { content: '\e8a1'; } /* '' */
.icon-archive:before { content: '\e8a2'; } /* '' */
.icon-bell:before { content: '\e8a3'; } /* '' */
.icon-bookmark:before { content: '\e8a4'; } /* '' */
.icon-keyboard:before { content: '\e8a5'; } /* '' */
.icon-check:before { content: '\e8a6'; } /* '' */
.icon-up-dir:before { content: '\e8a7'; } /* '' */
.icon-erase:before { content: '\e8a8'; } /* '' */
.icon-layout:before { content: '\e8a9'; } /* '' */
.icon-menu:before { content: '\e8aa'; } /* '' */
.icon-target:before { content: '\e8ab'; } /* '' */
.icon-ticket:before { content: '\e8ac'; } /* '' */
.icon-megaphone:before { content: '\e8ad'; } /* '' */
.icon-bucket:before { content: '\e8ae'; } /* '' */
.icon-loop:before { content: '\e8af'; } /* '' */
.icon-docs:before { content: '\e8b0'; } /* '' */
.icon-chart-line:before { content: '\e8b1'; } /* '' */
.icon-hourglass:before { content: '\e8b2'; } /* '' */
.icon-clock:before { content: '\e8b3'; } /* '' */
.icon-record:before { content: '\e8b4'; } /* '' */
.icon-stop:before { content: '\e8b5'; } /* '' */
.icon-play:before { content: '\e8b6'; } /* '' */
.icon-down-2:before { content: '\e8b7'; } /* '' */
.icon-left-2:before { content: '\e8b8'; } /* '' */
.icon-right-2:before { content: '\e8b9'; } /* '' */
.icon-up-2:before { content: '\e8ba'; } /* '' */
.icon-building:before { content: '\e8bb'; } /* '' */
.icon-check-green:before { content: '\e8bc'; } /* '' */
.icon-flag:before { content: '\e8bd'; } /* '' */
.icon-chevron-down:before { content: '\e8be'; } /* '' */
.icon-chevron-left:before { content: '\e8bf'; } /* '' */
.icon-chevron-right:before { content: '\e8c0'; } /* '' */
.icon-chevron-up:before { content: '\e8c1'; } /* '' */
.icon-close:before { content: '\e8c2'; } /* '' */
.icon-flag-filled:before { content: '\e8c3'; } /* '' */
.icon-info-1:before { content: '\e8c4'; } /* '' */
.icon-phone-2:before { content: '\e8c5'; } /* '' */
.icon-plus-2:before { content: '\e8c6'; } /* '' */
.icon-photo:before { content: '\e8c7'; } /* '' */
.icon-question-1:before { content: '\e8c8'; } /* '' */
.icon-search-2:before { content: '\e8c9'; } /* '' */
.icon-warning-1:before { content: '\e8ca'; } /* '' */
.icon-wrench-1:before { content: '\e8cb'; } /* '' */
.icon-attach-1:before { content: '\e8cc'; } /* '' */
.icon-edit:before { content: '\e8cd'; } /* '' */
.icon-file-excel:before { content: '\e8ce'; } /* '' */
.icon-file-generic:before { content: '\e8cf'; } /* '' */
.icon-file-image:before { content: '\e8d0'; } /* '' */
.icon-file-pdf:before { content: '\e8d1'; } /* '' */
.icon-file-powerpoint:before { content: '\e8d2'; } /* '' */
.icon-file-text:before { content: '\e8d3'; } /* '' */
.icon-file-word:before { content: '\e8d4'; } /* '' */
.icon-bell-2:before { content: '\e8d5'; } /* '' */
.icon-exit:before { content: '\e8d6'; } /* '' */
.icon-lock-2:before { content: '\e8d7'; } /* '' */
.icon-profile-2:before { content: '\e8d8'; } /* '' */
.icon-lock-1:before { content: '\e8d9'; } /* '' */
.icon-eye-1:before { content: '\e8da'; } /* '' */
.icon-tag:before { content: '\e8db'; } /* '' */
.icon-thumbs-up:before { content: '\e8dc'; } /* '' */
.icon-pencil-2:before { content: '\e8dd'; } /* '' */
.icon-comment-1:before { content: '\e8de'; } /* '' */
.icon-location-2:before { content: '\e8df'; } /* '' */
.icon-cup-1:before { content: '\e8e0'; } /* '' */
.icon-trash-2:before { content: '\e8e1'; } /* '' */
.icon-doc:before { content: '\e8e2'; } /* '' */
.icon-note:before { content: '\e8e3'; } /* '' */
.icon-cog:before { content: '\e8e4'; } /* '' */
.icon-params:before { content: '\e8e5'; } /* '' */
.icon-calendar-1:before { content: '\e8e6'; } /* '' */
.icon-sound:before { content: '\e8e7'; } /* '' */
.icon-clock-1:before { content: '\e8e8'; } /* '' */
.icon-lightbulb:before { content: '\e8e9'; } /* '' */
.icon-tv:before { content: '\e8ea'; } /* '' */
.icon-desktop:before { content: '\e8eb'; } /* '' */
.icon-mobile-1:before { content: '\e8ec'; } /* '' */
.icon-cd-1:before { content: '\e8ed'; } /* '' */
.icon-inbox:before { content: '\e8ee'; } /* '' */
.icon-globe:before { content: '\e8ef'; } /* '' */
.icon-cloud:before { content: '\e8f0'; } /* '' */
.icon-paper-plane-1:before { content: '\e8f1'; } /* '' */
.icon-fire:before { content: '\e8f2'; } /* '' */
.icon-graduation-cap:before { content: '\e8f3'; } /* '' */
.icon-megaphone-1:before { content: '\e8f4'; } /* '' */
.icon-database-1:before { content: '\e8f5'; } /* '' */
.icon-key:before { content: '\e8f6'; } /* '' */
.icon-beaker:before { content: '\e8f7'; } /* '' */
.icon-truck:before { content: '\e8f8'; } /* '' */
.icon-money:before { content: '\e8f9'; } /* '' */
.icon-food:before { content: '\e8fa'; } /* '' */
.icon-shop:before { content: '\e8fb'; } /* '' */
.icon-diamond:before { content: '\e8fc'; } /* '' */
.icon-t-shirt:before { content: '\e8fd'; } /* '' */
.icon-wallet:before { content: '\e8fe'; } /* '' */
.icon-location-1:before { content: '\e8ff'; } /* '' */
.icon-rec-cam:before { content: '\e900'; } /* '' */
.icon-add-folder:before { content: '\e901'; } /* '' */
.icon-cam:before { content: '\e902'; } /* '' */
.icon-edit-pen:before { content: '\e903'; } /* '' */
.icon-mic:before { content: '\e904'; } /* '' */
.icon-turn-right:before { content: '\e905'; } /* '' */
.icon-nav-building:before { content: '\e907'; } /* '' */
.icon-nav-home:before { content: '\e908'; } /* '' */
.icon-nav-inbox:before { content: '\e909'; } /* '' */
.icon-offline:before { content: '\e90c'; } /* '' */
.icon-logout:before { content: '\e90d'; } /* '' */
.icon-preferences:before { content: '\e90e'; } /* '' */
.icon-notification:before { content: '\e90f'; } /* '' */
.icon-warning:before { content: '\e910'; } /* '' */
.icon-warning-color:before { content: '\e911'; } /* '' */
.icon-warning-round:before { content: '\e912'; } /* '' */
.icon-star-2:before { content: '\e913'; } /* '' */
.icon-gen_int_rp:before { content: '\e914'; } /* '' */
.icon-hse:before { content: '\e915'; } /* '' */
.icon-hvac:before { content: '\e916'; } /* '' */
.icon-user-1:before { content: '\e917'; } /* '' */
.icon-landscape:before { content: '\e918'; } /* '' */
.icon-light:before { content: '\e919'; } /* '' */
.icon-locks_keys:before { content: '\e91a'; } /* '' */
.icon-mats:before { content: '\e91b'; } /* '' */
.icon-move-1:before { content: '\e91c'; } /* '' */
.icon-paint:before { content: '\e91d'; } /* '' */
.icon-pest:before { content: '\e91e'; } /* '' */
.icon-plum_drn:before { content: '\e91f'; } /* '' */
.icon-plumbing:before { content: '\e920'; } /* '' */
.icon-roofing:before { content: '\e921'; } /* '' */
.icon-security:before { content: '\e922'; } /* '' */
.icon-shredding:before { content: '\e923'; } /* '' */
.icon-signage:before { content: '\e924'; } /* '' */
.icon-snow:before { content: '\e925'; } /* '' */
.icon-ten_serv:before { content: '\e926'; } /* '' */
.icon-utilities:before { content: '\e927'; } /* '' */
.icon-waste:before { content: '\e928'; } /* '' */
.icon-win_doors:before { content: '\e929'; } /* '' */
.icon-appliances:before { content: '\e92a'; } /* '' */
.icon-bank_equip:before { content: '\e92b'; } /* '' */
.icon-default:before { content: '\e92c'; } /* '' */
.icon-doors:before { content: '\e92d'; } /* '' */
.icon-dump_serv:before { content: '\e92e'; } /* '' */
.icon-electrical:before { content: '\e92f'; } /* '' */
.icon-elevating:before { content: '\e930'; } /* '' */
.icon-fire_equip:before { content: '\e931'; } /* '' */
.icon-food_serv:before { content: '\e932'; } /* '' */
.icon-furniture:before { content: '\e933'; } /* '' */
.icon-gen_ext_rp:before { content: '\e934'; } /* '' */
.icon-building-1:before { content: '\e935'; } /* '' */
.icon-chevron-1:before { content: '\e936'; } /* '' */
.icon-videocam:before { content: '\e937'; } /* '' */
.icon-camera-1:before { content: '\e938'; } /* '' */
.icon-dollar:before { content: '\f155'; } /* '' */
body{
    margin: 0;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #f7fbfe;
    .container, .container-fluid, .container-xxl, .container-xl, .container-lg, .container-md, .container-sm{
        margin-left: 0;
    }
    .bgis-card {
        color: #212121;
        transition: box-shadow 280ms cubic-bezier(.4,0,.2,1);
        position: relative;
        padding: 1rem;
        margin: .75rem .25rem 0;
        font-size: 1rem;
        border-radius: 1px;
        background-color: #fff;
        box-shadow: 0 2px 4px 0 rgba(0,0,0,.5);
    }
    
    .button-group{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border: 1px #919191 solid;
        margin: 0.2rem 0;
        border-radius: 2px;
        div{
            background-color:white;
            color: $primary;
            width: 100%;
            height: 2.25rem;
            text-align: center;
            padding:.5rem;
            cursor: pointer;
            &:not(:last-child) {
                border-right: 1px #919191 solid;
            }
            &.hilite{
                background-color:$primary;
                color:white;
                text-align: center;
                vertical-align: middle;
            }
        }
    }
    .form-check-input{
        margin-right: 0.2rem;
        width: 1rem;
        height: 1rem;
        padding: 0.1rem;
        border-radius: 0!important;
    }
    .toast-container{
        top: 4rem;
        right: 0;
    }
    .markFont{
        padding: 0;
        font-weight: bold;
    }
}
body.dark{
    font-size: 1rem;
}